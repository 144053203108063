import React, {useEffect,useState} from 'react';
import Frame from "../components/frame";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { Link, useNavigate } from 'react-router-dom';
import toast, { useToasterStore } from 'react-hot-toast';
import { getReferrals, userDetails, userLogout } from '../api/auth';
import { BaseCurrencySymbol, CurrencySymbol } from '../utils/constants';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      member:''
    }
  }

  handleLogout = async()=>{
    this.setState({loading:true});
    let token = localStorage.getItem('token');
    await userLogout(token);
    localStorage.setItem('token','');
    this.setState({loading:false});
    this.props.navigate('/login');
  }

  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true}>
        {this.props.is_fetching || this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header header-style2 fixed-top bg-menuDark d-flex align-items-center">
          <Link to={'/user_info'} className="left back-btn"><i className="icon-left-btn"></i></Link>
          <h5 className="mb-0">Referal Program 
         {/* <span data-bs-toggle="modal" data-bs-target="#Referal_Program" className='ms-2 theme_color font-15'><i className="icon icon-question"></i></span>*/}
          </h5>
          <div className="d-flex align-items-center gap-8 header_r_p">
            <a href="#logout" data-bs-toggle="modal" className="icon_circle"><img src="/assets/img/user-logout.png" alt="" /></a>
          </div>
        </div>

        <div className="pt-55 pb-70">
        <div className="tf-container  mb-25">
          <div className='bg_box gradient_box_border mt-20'>
            <div className='d-flex p-3 align-items-center justify-content-between'  data-bs-toggle="collapse" data-bs-target="#match1">
              <p className="mb-0 fw-500 font-14">Wallet Balance</p>
              <div className="mb-0 mr-4 arrow_down position-relative text-white font-15">{parseFloat(this.props.referral_list?.wallet_balance).toFixed(2)} {CurrencySymbol}</div>
            </div>
            <div id="match1" className="collapse">
              <ul className="action_sheet_currency_select">
                <li>Total Members <span>{this.props.referral_list?.total_members}</span></li>
                <li>Direct Referral Income <span>{parseFloat(this.props.referral_list?.direct_imcome).toFixed(2)} {CurrencySymbol}</span></li>
                <li>Manager Income <span>{parseFloat(this.props.referral_list?.manager_income).toFixed(2)} {CurrencySymbol}</span></li>
                <li>Level Income <span>{parseFloat(this.props.referral_list?.level_income).toFixed(2)} {CurrencySymbol}</span></li>
                <li>Total Dividend Income <span>{parseFloat(this.props.referral_list?.total_dividend_income).toFixed(2)} {CurrencySymbol}</span></li>
              </ul>
            </div>
          </div>
         <div className='text-center mt-20'>
          <Link to={'/analytics'} className="btn btn-info">View Analytics</Link>
         </div>
        </div>
       
          <ul className="menu-tab-v3 mt-14 nav-justified" role="tablist">
            <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#referal_tab1" role="presentation">
              INVITE
            </li>
            <li className="nav-link" role="presentation"  data-bs-toggle="tab" data-bs-target="#referal_tab2">
              MEMBERS
            </li>
            <li className="nav-link" role="presentation"  data-bs-toggle="tab" data-bs-target="#referal_tab3">
              ACTIVITY
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade active show" id="referal_tab1" role="tabpanel">
              <div className="tf-container pt-16">
                <h3 className='text-center text_shadow2 mb-20'>Refer & Earn Big!</h3>
                <div className='gradient_box mb-20'>
                  <div className='gradient_box_top d-flex align-items-center justify-content-between'>
                    <h4 className='mb-0'>Your Referal Code</h4>
                    {parseFloat(this.props.userDetails?.totalDeposit)>0 ? (
                      <div className='text-white font-16'>
                        <span data-bs-toggle="dropdown" aria-expanded="false"><i className="icon icon-share"></i></span>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" >
                            <WhatsappShareButton url={`${window.location.host}/register/${this.props.userDetails?.username}`}>
                              <WhatsappIcon size={20} round />
                              <span> Share on WhatsApp</span>
                            </WhatsappShareButton>
                          </a>
                          <a className="dropdown-item" >
                            <TelegramShareButton url={`${window.location.host}/register/${this.props.userDetails?.username}`}>
                              <TelegramIcon size={20} round />
                              <span> Share on Telegram</span>
                            </TelegramShareButton>
                          </a>
                          <a className="dropdown-item" >
                            <FacebookShareButton url={`${window.location.host}/register/${this.props.userDetails?.username}`}>
                              <FacebookIcon size={20} round />
                              <span> Share on facebook</span>
                            </FacebookShareButton>
                          </a>
                          <a className="dropdown-item" >
                            <TwitterShareButton  url={`${window.location.host}/register/${this.props.userDetails?.username}`}>
                              <TwitterIcon size={20} round />
                              <span > Share on twitter</span>
                            </TwitterShareButton>
                          </a>
                        </div>
                        <CopyToClipboard text={`${window.location.host}/register/${this.props.userDetails?.username}`}
                          onCopy={() => toast.success('Copied Successfully!')}>
                          <span className='ms-2'><i className="icon icon-copy"></i></span>
                        </CopyToClipboard>                      
                      </div>
                      ):(
                        <div className='text-white font-16'>
                          <i onClick={()=>toast.error('Please add funds to refer!!')} className="icon icon-share"></i>
                          <span onClick={()=>toast.error('Please add funds to copy referral link!!')} className='ms-2'><i className="icon icon-copy"></i></span>
                        </div>                        
                      )}
                  </div>
                  <div className='gradient_box_body'>
                    <h2 className='font-25 mb-10 mt-10 text-center'>{this.props.userDetails?.username}</h2>
                  </div>
                </div>
                <div className='lock_box_border'>
                  <div className='lock_box_top p-3'>
                    <img className='unlock-mission_img' src="/assets/img/unlock-mission.jpg" alt="img" />
                  </div>
                  <div className='lock_box_bottom p-3'>
                    <p className='mb-2'>Unlock missions by becoming a Community Manager.</p>
                    <p className='text-white'>The qualification criteria are:</p>
                    <ul className='mb-3'>
                      <li>A refundable security <span className='theme_color'>deposit of 500 {BaseCurrencySymbol}.</span></li>
                      <li>Recruit direct <span className='theme_color'>7 new depositing members.</span></li>
                      <li><span className='theme_color'>Get 5%</span>  from your linked members deposits.</li>
                    </ul>
                    <h4 className='text-center'>Or</h4>
                    <ul className='mb-0'>
                      <li>Complete <span className='theme_color'>total 500</span> active team members.</li>
                    </ul>
                  </div>
                </div>
                
                {/* <div className="heading_text mb-20 text-center">
                  <h3>Your Benefits as a referrer</h3>
                </div>
                <div className="bg_box p-3 text-center mb-20">
                  <div className="accent-box-v5 p-4 mb-10 ">
                    <h5 className='mb-0'>$0,20x Per member Playing a paid game (per day)</h5>
                  </div>
                  <p>Lorem Ipsum is simply dummy text of the printing and <span className='theme_color'>typesetting</span>  industry.</p>
                  <div className="accent-box-v5 p-4 mb-10 ">
                    <h5 className='mb-0'>$0,20x Per member Playing a paid game (per day)</h5>
                  </div>
                </div>

                <div className="heading_text mb-20 text-center">
                  <h3>Benefits for your Members</h3>
                </div>
                <div className="bg_box p-4 text-center mb-20">
                  <div className="accent-box-v5 p-3 mb-10 ">
                    <h5 className='mb-0'>$0,20x Per member Playing a paid game (per day)</h5>
                  </div>
                  <p className='mb-0'>Lorem Ipsum is simply dummy text of the printing and <span className='theme_color'>typesetting</span>  industry.</p>
                  
                </div> */}

              </div>
            </div>
            <div className="tab-pane fade" id="referal_tab2" role="tabpanel">
              <div className="tf-container">
                <div className='d-flex align-items-center justify-content-between mb-20'>
                  <div>Level</div>
                  <div>Member Count</div>
                  <div>Users</div>
                </div>
                {this.props.referral_list?.members?.length>0?(
                  this.props.referral_list?.members?.map(function(member,index){
                    return (
                      <div key={`mem_${index}`} className="bg_box p-3 mb-10 d-flex align-items-center justify-content-between">
                        <div className='referal_members_game width_33'>
                          <h6 className='f'>{member?.level}</h6>
                        </div>
                        <div className='theme_color font-12 text-center fw-600 width_33 '>{member?.user_count}</div>
                        {member?.level==1?(
                        <div className='width_33 text-end'>
                          <button onClick={()=>this2.setState({member:member})} data-bs-toggle="modal" data-bs-target="#view_member_modal" className='btn_style1 border2'>View</button>
                        </div>
                        ):(
                          <div className='width_33 text-end'></div>
                        )}
                      </div>
                    )
                  })
                ):(
                  <>
                    <img className="no-img" src="/assets/img/bear-new.png" alt="" />
                    <h4 className='text-center'>Oops! No data to show</h4>
                  </>
                )}
              </div>
            </div>
            <div className="tab-pane fade" id="referal_tab3" role="tabpanel">
              <div className="tf-container">
                <div className='d-flex align-items-center justify-content-between mb-20'>                  
                  <div>Level</div>
                  <div>User Count</div>
                  <div>Amount</div>
                  <div>Bonus</div>
                </div>
                {this.props.referral_list?.activity?.length>0?(
                  this.props.referral_list?.activity?.map(function(activity,index){
                    return (
                      <div key={`act_${index}`} className="bg_box p-3 mb-10 d-flex align-items-center justify-content-between">
                        <div className='referal_members_game'>                          
                          <h6 className='f'>{activity?.level}</h6>
                        </div>
                        <div className='theme_color font-12 fw-600'>{activity?.user_count}</div>
                        <div>{parseFloat(activity?.ref_amount)>0 ? parseFloat(activity?.ref_amount).toFixed(2)+` ${CurrencySymbol}` : `0 ${CurrencySymbol}`}</div>
                        <div>{parseFloat(activity?.ref_comission)>0 ? parseFloat(activity?.ref_comission).toFixed(2)+` ${CurrencySymbol}` : `0 ${CurrencySymbol}`}</div>
                      </div>
                    )
                  })
                ):(
                  <>
                    <img className="no-img" src="/assets/img/bear-new.png" alt="" />
                    <h4 className='text-center'>Oops! No data to show</h4>
                  </>
                )}                
              </div>
            </div>
          </div>
         
        </div>

        <div className="modal fade action-sheet" id="view_member_modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">View Member</h4>
              </div>
              <div className='p-0'>
                <ul class="action_sheet_currency_select">
                  
                      {this2.state.member?.level==1?(
                        // member?.username.toString()
                        this2.state.member?.username.length>0?(
                          this2.state.member?.username.map(function(value){
                            return (<li>{value}</li>)
                          })
                        ):(null)
                      ):(null)}
                    
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade action-sheet" id="Referal_Program">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Referal Program</h4>
              </div>
              <div className='p-3'>
                <div className="accent-box-v5 mb-10">
                    <h4>The Invite Tab</h4>
                    <p className='mb-0'>Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.</p>
                </div>
                <div className="accent-box-v5 mb-10">
                    <h4>The Member tab</h4>
                    <p className='mb-0'>Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.</p>
                </div>
                <div className="accent-box-v5 mb-0">
                    <h4>The Activity tab</h4>
                    <p className='mb-0'>Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="modal fade" id="logout">
        <div className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="header header-style2 d-flex align-items-center">                
              <h4 className="mb-0">Do you want to logout?</h4>
            </div>
            <hr className="m-0" />

            <div className="modal-body">
              <div className='header header-style2 d-flex align-items-center'>
                <a data-bs-dismiss="modal" className='btn_style1 border'>No</a>
                <a data-bs-dismiss="modal" onClick={this.handleLogout} className='btn_style1'>Yes</a>
              </div>                
            </div>
          </div>

        </div>
      </div>
       
      </Frame >
    );
  }
}

function UserInfoHOC(props) {
  const [user_Details,setUserDetails] = useState(null);
  const [referral_list,setReferralList] = useState([]);
  const [referral_transactions,setReferralTransactions] = useState([]);
  const [is_fetching, setFetching] = useState(true);
  const [load_data, setLoadData] = useState(true);

  useEffect(() => {
    (async () => {
      if(is_fetching){
        setLoadData(false);
        let token = localStorage.getItem('token');
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          setUserDetails(user_response?.data);
        }

        let referral_list_ = await getReferrals(token);
        if(referral_list_?.status){
          setReferralList(referral_list_?.data);
        }

        // let referral_trns = await getReferralTransactions(token);
        // if(referral_trns?.status){
        //   setReferralTransactions(referral_trns?.data);
        // }

        setFetching(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts 
    };

  }, [is_fetching,load_data]);

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const navigate = useNavigate();

  return <UserInfo
    is_fetching={is_fetching}
    userDetails={user_Details}
    referral_list={referral_list}
    referral_transactions={referral_transactions}
    setUserDetails={setUserDetails}
    setLoadData={setLoadData}
    navigate={navigate}
    {...props}
  />;
}

export default UserInfoHOC;